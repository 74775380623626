import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useAdsConversionTags } from './ads'

interface AllPagesProps {
  managementSetGuid: string
}

const AllPagesTag: React.FC<AllPagesProps> = ({ managementSetGuid }) => {
  const { data } = useAdsConversionTags(managementSetGuid)

  if (data) {
    return (
      <Helmet>
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${data.conversionTagId}`}
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${data.conversionTagId}');
         `}
        </script>
      </Helmet>
    )
  }

  return null
}

export default AllPagesTag
