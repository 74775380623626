import React from 'react'
import { ErrorPage500 } from '@toasttab/buffet-pui-error-pages'
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'

export default function Main(props: { children: React.ReactNode }) {
  return (
    <ErrorBoundary fallback={<ErrorPage500 />}>
      <div className='bg-gray-30' style={{ minHeight: '100vh' }}>
        <div
          className={
            'w-full h-full flex justify-center items-center bg-gray-30 p-8'
          }
          style={{ minHeight: '100vh' }}
        >
          <main className={'w-full max-w-2xl'}>{props.children}</main>
        </div>
      </div>
    </ErrorBoundary>
  )
}
