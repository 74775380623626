import React from 'react'
import { useQueryParams } from '../../utils/utils'
import LeadFormView from './LeadFormView'
import { useEventType } from '../../api/invoices'
import { LeadForm } from './LeadForm'
import { useFlags } from '@toasttab/banquet-feature-flags'
import { StyleMetaProps, renderStyleMeta } from '@toasttab/sites-components'
import { Helmet } from 'react-helmet-async'
import { brandingPublicAssetURL } from '../../api/util'
import AllPagesTag from '../GooglePixels/AllPagesTag'

const LeadViewRouter: React.FC = () => {
  const params = useQueryParams()

  const { eventType, error } = useEventType(params.rx, params.ot)

  const { nvsLeadFormBranding } = useFlags()

  // When our lead pages are embedded in Sites, they always need to use Site's brand info
  // regardless of if the Rx has branding turned on
  const brandingStyle: StyleMetaProps = params.isSitesView
    ? {
        primaryColor: eventType?.restaurantBranding.sitesPrimaryColor,
        backgroundColor: eventType?.restaurantBranding.sitesBackgroundColor
          ? eventType?.restaurantBranding.sitesBackgroundColor
          : eventType?.restaurantBranding.sitesPrimaryColor,
        textColor: eventType?.restaurantBranding.sitesTextColor,
        fontFamily: eventType?.restaurantBranding.sitesFontFamily,
        headerFontFamily: eventType?.restaurantBranding.sitesHeaderFontFamily
      }
    : {
        primaryColor: eventType?.restaurantBranding.primaryColor,
        backgroundColor: eventType?.restaurantBranding.backgroundColor
          ? eventType?.restaurantBranding.backgroundColor
          : eventType?.restaurantBranding.primaryColor,
        textColor: eventType?.restaurantBranding.textColor,
        fontFamily: eventType?.restaurantBranding.fontFamily,
        headerFontFamily: eventType?.restaurantBranding.headerFontFamily
      }

  const HelmetStyling = (
    <Helmet>
      {
        nvsLeadFormBranding
          ? renderStyleMeta({ ...brandingStyle }, brandingPublicAssetURL)
          : renderStyleMeta({}, brandingPublicAssetURL) // Uses toast black defaults
      }
    </Helmet>
  )

  // params.form indicates that they want the embeddable form
  if (eventType && params.ot && params.rx && params.form) {
    return (
      <>
        {HelmetStyling}
        <AllPagesTag managementSetGuid={eventType.managementSetGuid} />
        <LeadForm eventType={eventType} />
      </>
    )
  }

  if (eventType && params.ot && params.rx) {
    return (
      <>
        {HelmetStyling}
        <AllPagesTag managementSetGuid={eventType.managementSetGuid} />
        <LeadFormView eventType={eventType} error={error} />
      </>
    )
  }

  return null
}

export default LeadViewRouter
