import React, { useState } from 'react'
import { useEvent } from './EventProvider'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import ConfirmEstimateButton from './ConfirmEstimateButton'
import RequiredEntry from '../LeadView/RequiredEntry'
import { HelperText } from '@toasttab/buffet-pui-text-base'

export default function EventSignature() {
  const { event, isConfirmed } = useEvent()
  const [signature, setSignature] = useState('')
  const [error, setError] = useState('')

  if (!event?.signatureRequired || isConfirmed) return null

  return (
    <div
      className={'bg-white rounded px-8 pb-8 mt-4'}
      id={'confirmation-button'}
    >
      <div
        className={
          'md:mb-4 fixed bottom-0 left-0 md:relative w-full bg-white md:bg-transparent p-4 md:p-0'
        }
      >
        <div className={'mb-4'}>
          <RequiredEntry showError={Boolean(error)} errorMessage={error}>
            <TextInput
              required
              placeholder={'Type full name here'}
              label={'Signature'}
              value={signature}
              onChange={(e) => {
                setSignature(e.target.value)
                if (e.target.value) {
                  setError('')
                }
              }}
            />
          </RequiredEntry>
        </div>
        <ConfirmEstimateButton
          signature={signature}
          setValidationError={setError}
        />
        <HelperText
          helperText={
            <div>
              By accepting this estimate, you agree to Toast's{' '}
              <a
                href={'https://pos.toasttab.com/terms-of-service'}
                target={'_blank'}
                rel='noreferrer'
                className={'text-primary-75 font-semibold'}
              >
                Guest Terms of Service
              </a>{' '}
              and acknowledge that your information will be processed pursuant
              to Toast's{' '}
              <a
                href='https://pos.toasttab.com/privacy'
                target={'_blank'}
                rel='noreferrer'
                className={'text-primary-75 font-semibold'}
              >
                Privacy Statement
              </a>
              .
            </div>
          }
        />
      </div>
    </div>
  )
}
