import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useData } from '../DataProvider'

import {
  AdyenResponse,
  SEMI_PAYMENT_API_BASE_URL,
  ADYEN_CHECKOUT_CONFIG,
  PAYMENT_SESSIONS_CREATE
} from './config'

import AdyenCheckout from '@adyen/adyen-web'
import { AdyenPaymentResponse, AdyenPaymentProps, ISessionData } from './types'
import { CoreOptions as AdyenCoreOptions } from '@adyen/adyen-web/dist/types/core/types'
import { convertToCents } from './helper'
import '@adyen/adyen-web/dist/adyen.css'
import './AdyenPayment.css'
import AdyenCheckoutError from '@adyen/adyen-web/dist/types/core/Errors/AdyenCheckoutError'

export const AdyenPayment = ({
  amount,
  checkGuid,
  orderGuid,
  sourceApp,
  shopperEmail,
  paymentType,
  onAuth
}: AdyenPaymentProps) => {
  const adyenContainerRef = useRef<HTMLDivElement>(null)
  const adyenCheckoutRef = useRef<AdyenCoreOptions | null>(null)

  const [adyenSession, setAdyenSession] = useState<ISessionData>({
    id: null,
    sessionData: null,
    publicClientKey: null
  })

  const { restaurant } = useData()
  const { country, currency, locale } = restaurant?.i18n || {}
  const [error, setError] = useState(false)
  const [paymentAttempt, setAttempt] = useState<number>(0)

  const configuration: AdyenCoreOptions = useMemo(() => {
    const { id, sessionData, publicClientKey } = adyenSession
    return {
      ...ADYEN_CHECKOUT_CONFIG,
      clientKey: publicClientKey!,
      session: { id, sessionData },
      locale: locale as string,
      onPaymentCompleted: (result: AdyenPaymentResponse) => {
        if (
          result.resultCode === AdyenResponse.REFUSED ||
          result.resultCode === AdyenResponse.ERROR
        ) {
          result.resultCode === AdyenResponse.REFUSED &&
            setAttempt(paymentAttempt + 1)
          setError(true)
        } else {
          onAuth(result)
        }
      },
      onError: (errorEvent: AdyenCheckoutError) => {
        console.error(errorEvent) // TODO: Use logging
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adyenSession, locale, onAuth])

  const setupAydenCard = useCallback(async () => {
    const checkout = await AdyenCheckout(configuration)

    if (adyenContainerRef.current) {
      /* @ts-ignore: Need to align the card types */
      adyenCheckoutRef.current = checkout
        .create(paymentType)
        .mount(adyenContainerRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuration])

  useEffect(() => {
    /* @ts-ignore: Need to align the card types */
    adyenCheckoutRef.current?.unmount()

    fetch(`${SEMI_PAYMENT_API_BASE_URL}${PAYMENT_SESSIONS_CREATE}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        value: {
          value: convertToCents(amount),
          currency
        },
        shopperEmail,
        country,
        locale,
        checkGuid,
        orderGuid,
        sourceApp,
        restaurantGuid: restaurant?.guid
      })
    })
      .then((response) => {
        if (!response.ok) {
          setError(true)
          throw new Error(`Error! status: ${response.status}`)
        }
        return response.json()
      })
      .then((data) => {
        const { sessionId, sessionData, publicClientKey } = data

        setAdyenSession({
          id: sessionId,
          sessionData,
          publicClientKey
        })
      })
      .catch(() => {
        setError(true)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, checkGuid, shopperEmail, restaurant?.guid, paymentAttempt])

  useEffect(() => {
    const { id, sessionData, publicClientKey } = adyenSession
    if (id && sessionData && publicClientKey && checkGuid) {
      setupAydenCard()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adyenSession, checkGuid])

  return (
    <>
      {error && (
        <div
          data-testid='error-message'
          className='m-3 rounded-lg mt-6 border border-solid border-gray-50 p-6 text-center text-error'
        >
          There was an issue processing your payment. Please try again.
        </div>
      )}
      <div
        className='bg-white pb-4'
        data-testid={`adyen-payment-${paymentType}`}
        ref={adyenContainerRef}
      />
    </>
  )
}
