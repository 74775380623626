import React from 'react'
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'
import { useEvent } from './EventProvider'

export default function EventContract() {
  const { event } = useEvent()

  if (!event.contract) return null

  return (
    <div className={'bg-white rounded px-8 pb-8'}>
      <ErrorBoundary
        fallbackMessage={
          'Sorry, we are unable to load the order summary for this estimate.'
        }
      >
        <p className={'type-overline text-secondary font-700 mb-3'}>
          Contract terms
        </p>
        <div className={'bg-gray-0 p-3 rounded'}>
          {/* This is sanitized on the backend before it's returned here */}
          <p dangerouslySetInnerHTML={{ __html: event.contract }} />
        </div>
      </ErrorBoundary>
    </div>
  )
}
