import React, { useEffect } from 'react'
import { formatPhone } from '@toasttab/buffet-pui-phone-utilities'
import { printAddressBranded, useQueryParams } from '../../utils/utils'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'
import { ErrorPage404 } from '@toasttab/buffet-pui-error-pages'
import { RestaurantBranding } from '../../types/InvoiceTypes'
import { LeadForm } from './LeadForm'
import { PublicEventTypeResponse } from '../../types/EventTypes'
import './styles.css'
import { useFlags } from '@toasttab/banquet-feature-flags'
import cx from 'classnames'

interface LeadFormViewProps {
  eventType: PublicEventTypeResponse | null
  error: any
}

const LeadFormView: React.FC<LeadFormViewProps> = ({
  eventType,
  error
}: LeadFormViewProps) => {
  const params = useQueryParams()
  const { nvsLeadDescriptionRichText } = useFlags()

  const restaurantBranding: RestaurantBranding | undefined =
    eventType?.restaurantBranding

  const leadTitle = eventType?.leadTitle || `${eventType?.name} Inquiry`
  const leadDescription = eventType?.leadDescription || null
  const leadWebsiteUrl = eventType?.leadWebsiteUrl || null
  const leadHeaderImageUrl = eventType?.leadHeaderImageUrl || null
  const logo = restaurantBranding?.logo

  const country = eventType?.restaurantCountry
    ? eventType?.restaurantCountry
    : 'US'

  useEffect(() => {
    if (restaurantBranding) {
      document.title = `${leadTitle}${
        restaurantBranding.name ? ` - ${restaurantBranding.name}` : ''
      }`
    }
  }, [params, restaurantBranding, leadTitle])

  if (!restaurantBranding) {
    return <Skeleton />
  }

  if (!params?.rx || error) {
    return <ErrorPage404 />
  }

  function getBrandedBackgroundTextColor() {
    if (!eventType?.restaurantBranding?.cateringBrandingEnabled) {
      return 'text-black'
    }

    if (eventType.restaurantBranding.backgroundColor) {
      return 'text-tsw-primary-text-color'
    }

    if (eventType.restaurantBranding.primaryColor) {
      return 'text-tsw-primary-contrasting-color'
    }

    return 'text-black'
  }

  const HeaderImage = () => {
    return (
      <div className='inline-block overflow-hidden align-middle'>
        {leadHeaderImageUrl ? (
          <>
            <div
              className='absolute top-0 left-0 w-full h-56 md:h-80 opacity-100 bg-cover bg-center'
              style={{
                backgroundImage: `url("${leadHeaderImageUrl}")`
              }}
            >
              <div className='absolute top-0 left-0 z-0 w-full h-56 md:h-80 opacity-60 bg-black' />
            </div>
          </>
        ) : null}
      </div>
    )
  }

  const RestaurantName = () => {
    return (
      <div className='md:w-2/5'>
        <h3
          className={cx(
            'relative pb-1 md:text-left type-headline-2 brandedHeaderText',
            leadHeaderImageUrl || logo ? 'pt-12 md:pt-44' : 'pt-4 md:pt-16',
            !leadHeaderImageUrl && getBrandedBackgroundTextColor(), // Text will be on top of the solid background color
            !!leadHeaderImageUrl && 'text-white' // Text will be on top of the dimmed header image
          )}
        >
          {restaurantBranding.name}
        </h3>
      </div>
    )
  }

  const DescriptionAndContactInfo = () => {
    return (
      <div
        className={cx(
          'relative flex flex-col md:w-2/5 mt-8 brandedBodyText',
          getBrandedBackgroundTextColor(),
          leadHeaderImageUrl ? 'md:mt-0' : 'md:mt-4'
        )}
      >
        {leadDescription && nvsLeadDescriptionRichText && (
          <>
            {/* This is sanitized on the backend before it's returned here */}
            <p
              className='mb-10 pb-4 leadFormDescription'
              dangerouslySetInnerHTML={{ __html: leadDescription }}
            />
          </>
        )}
        {leadDescription && !nvsLeadDescriptionRichText && (
          <>
            <p className='mb-8 pb-4 type-default whitespace-pre-line '>
              {leadDescription}
            </p>
          </>
        )}
        <ul>
          <li className={'flex mb-4 type-default underline'}>
            <p className=''>
              {printAddressBranded({
                restaurantBranding: { ...restaurantBranding, country },
                singleLine: true
              })}
            </p>
          </li>
          {leadWebsiteUrl && (
            <li className='flex mb-4 type-default underline'>
              <a href={leadWebsiteUrl} target={'_blank'} rel='noreferrer'>
                {leadWebsiteUrl.replace(/^https?:\/\//, '')}
              </a>
            </li>
          )}
          <li className='flex type-default underline'>
            <a href={`tel:${formatPhone(restaurantBranding.phone, country)}`}>
              {formatPhone(restaurantBranding.phone, country)}
            </a>
          </li>
        </ul>
      </div>
    )
  }

  const LeadFormWrapper = () => {
    return (
      <div
        className={cx(
          'relative w-full mr-4 md:w-3/5 md:mb-12 -mt-6  px-8 py-6 bg-white shadow-xl rounded-md ',
          !leadHeaderImageUrl && !logo
            ? 'md:-mt-24'
            : leadHeaderImageUrl
            ? 'md:-mt-[19rem]' // 19rem would be "76" if that existed as a preset
            : 'md:-mt-52'
        )}
      >
        {logo ? (
          <>
            <div className='flex justify-center items-center mb-6 w-full'>
              <img className='max-h-28 max-w-full' src={logo} alt='Logo' />
            </div>
          </>
        ) : null}
        <LeadForm eventType={eventType} />
      </div>
    )
  }

  return (
    <div className='w-full bg-tsw-background-color z-0'>
      <main className={'pl-4 pr-6 max-w-6xl mx-auto'}>
        <div
          className={cx(
            leadHeaderImageUrl ? 'mb-24' : 'mb-12 md:mb-0',
            'text-center'
          )}
        >
          <HeaderImage />
          <RestaurantName />
        </div>

        <div
          className={
            'w-full flex flex-col-reverse md:flex-row md:space-x-24 md:justify-center type-default text-default'
          }
        >
          <DescriptionAndContactInfo />
          <LeadFormWrapper />
        </div>
      </main>
    </div>
  )
}

export default LeadFormView
