import React from 'react'
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'
import { useEvent } from './EventProvider'
import { FormFieldType } from '../../types/EventTypes'

export default function EventFormFieldsTable() {
  const { event } = useEvent()

  if (!event.formValues.length) return null

  const formValues = event.formValues.filter(
    (i) => i.formField.type !== FormFieldType.EMPLOYEE
  )

  return (
    <div className={'bg-white rounded p-8'}>
      <ErrorBoundary
        fallbackMessage={
          'Sorry, we are unable to load the order summary for this estimate.'
        }
      >
        <table className={'w-full border-collapse border-0'}>
          <tbody>
            {formValues.map((formValue, i) => {
              let value = formValue.value

              // some fields contain JSON arrays, so try to parse else fall back to string value
              try {
                value = JSON.parse(value)?.join(', ')
                // eslint-disable-next-line no-empty
              } catch (e: any) {}

              return (
                <tr
                  key={i}
                  className={`border-b border-DEFAULT ${
                    i === 0 ? 'border-t' : ''
                  }`}
                >
                  <td
                    style={{ lineHeight: 2 }}
                    className={
                      'w-48 align-top py-2 pr-2 type-overline text-secondary font-700'
                    }
                  >
                    {formValue.formField.label || 'Unnamed Menu Item'}
                  </td>
                  <td className={'align-top py-2 pr-2 type-default'}>
                    {value ? (
                      <div dangerouslySetInnerHTML={{ __html: value }} />
                    ) : (
                      'N/A'
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </ErrorBoundary>
    </div>
  )
}
