import React, { useMemo } from 'react'
import { GoogleMap, LoadScript } from '@react-google-maps/api'
import { AutorenewIcon } from '@toasttab/buffet-pui-icons'
import MapMarker from './MapMarker'

const MAPS_API_KEY = 'AIzaSyBIrycPJk2HkrpUFCzi1bxmsnBxCuQJ9G0'

export function getLocationPanelId(guid: string) {
  return `panel_${guid}`
}

export interface MapLocation {
  guid: string
  name: string
  latitude?: number
  longitude?: number
}

type Props = {
  locations: MapLocation[] | undefined
  selectedGuid: string | undefined
  onSelectedGuidChange: (guid: string | undefined) => void
}

// Centers a bounds on a given lat/long, and then zooms such that a `km` x `km` square is visible
function boundsAround(latitude: number, longitude: number, km: number) {
  const bounds = new google.maps.LatLngBounds()

  const earth = 6378.137 //radius of the earth in kilometer
  const pi = Math.PI
  const m = 1 / (((2 * pi) / 360) * earth) //1 km in degree

  const NELongitude = longitude + (km * m) / Math.cos(latitude * (pi / 180))
  const NELatitude = latitude + km * m

  const SWLongitude = longitude - (km * m) / Math.cos(latitude * (pi / 180))
  const SWLatitude = latitude - km * m

  bounds.extend(new google.maps.LatLng(NELatitude, NELongitude))
  bounds.extend(new google.maps.LatLng(SWLatitude, SWLongitude))

  return bounds
}

function Map(props: Props) {
  const { locations, selectedGuid, onSelectedGuidChange } = props
  const mapRef = React.useRef<google.maps.Map | undefined>()

  const bounds = useMemo(() => new google.maps.LatLngBounds(), [])

  const selectedLocation = locations?.find((i) => i.guid === selectedGuid)

  const markers = useMemo(() => {
    return locations?.map((loc) => {
      if (loc.latitude && loc.longitude) {
        bounds.extend(new google.maps.LatLng(loc.latitude, loc.longitude))
        return (
          <MapMarker
            onClick={() => {
              return onSelectedGuidChange(loc.guid)
            }}
            isSelected={selectedGuid === loc.guid}
            position={{
              lat: loc.latitude,
              lng: loc.longitude
            }}
            title={loc.name}
            key={loc.guid}
          />
        )
      }

      return null
    })
    // eslint-disable-next-line
  }, [locations, selectedGuid])

  // if locations change, reset the bounds to fit the new locations
  React.useEffect(() => {
    if (mapRef.current) {
      mapRef.current.fitBounds(bounds)
    }
    // eslint-disable-next-line
  }, locations?.map((i) => i.guid) || [null])

  // if selected location changes zoom in on new location
  React.useEffect(() => {
    if (
      mapRef.current &&
      selectedLocation?.latitude &&
      selectedLocation?.longitude
    ) {
      const zoom = boundsAround(
        selectedLocation.latitude,
        selectedLocation.longitude,
        5
      )
      mapRef.current.fitBounds(zoom)
    }
    // eslint-disable-next-line
  }, [selectedGuid])

  if (!locations?.length) {
    return (
      <div className={'map-container'}>
        <iframe
          title={'Map'}
          width='100%'
          height='100%'
          style={{ border: 0 }}
          loading='lazy'
          allowFullScreen
          referrerPolicy='no-referrer-when-downgrade'
          src={`https://www.google.com/maps/embed/v1/place?key=${MAPS_API_KEY}&q=Boston+MA`}
        />
      </div>
    )
  }

  return (
    <div className={'map-container'}>
      <GoogleMap
        mapContainerStyle={{
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.9)'
        }}
        options={{ disableDefaultUI: true }}
        zoom={8}
        onLoad={(map: google.maps.Map) => {
          mapRef.current = map
          if (locations?.length) {
            map.fitBounds(bounds)
          }
        }}
      >
        {markers}
      </GoogleMap>
    </div>
  )
}

const LocationMap = (props: Props) => (
  <LoadScript
    googleMapsApiKey={MAPS_API_KEY}
    loadingElement={
      <div className='flex justify-center w-full h-full items-center'>
        <AutorenewIcon className='animate-spin text-secondary' />
      </div>
    }
  >
    <Map {...props} />
  </LoadScript>
)
export default LocationMap
