import React, { ReactNode } from 'react'
import { formatPhone } from '@toasttab/buffet-pui-phone-utilities'
import { formatAddress, getFormattedMilesAway } from '../../utils/formatting'
import { Address } from '../../types/CateringLocationTypes'
import cx from 'classnames'

interface Props extends React.HTMLAttributes<HTMLElement> {
  id: string
  restaurantName: string
  locationName?: string
  distanceMiles?: number | null
  address: Address
  availabilityIndicator?: ReactNode
  isSelected: boolean
}

const LocationCard = ({
  restaurantName,
  locationName,
  distanceMiles,
  address,
  availabilityIndicator,
  children,
  id,
  onMouseEnter,
  onMouseLeave,
  isSelected
}: React.PropsWithChildren<Props>) => {
  return (
    <div className={'pt-6'} id={id}>
      <section
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={cx('bg-white', 'shadow-md', 'rounded', {
          'border-solid border-2 border-primary-75 card-selected-padding': isSelected,
          'p-8': !isSelected,
          'border-transparent': !isSelected
        })}
        data-testid={id}
      >
        <div className='flex justify-between'>
          <div className='mb-3'>
            <header className='font-semibold type-headline-5'>
              {restaurantName}
            </header>
            {Boolean(locationName) && (
              <div className='text-gray-75 type-large'>{locationName}</div>
            )}
          </div>
          <div className='pt-1 pl-1 text-secondary type-caption'>
            {getFormattedMilesAway(distanceMiles)}
          </div>
        </div>
        {availabilityIndicator}
        {address.phone && <div>{formatPhone(address.phone)}</div>}
        <div>{formatAddress(address)}</div>
        <div className='mt-4'>{children}</div>
      </section>
    </div>
  )
}

export default LocationCard
