import envFromHostname from '@toasttab/env-from-hostname'

export const SEMI_PAYMENT_API_BASE_URL =
  envFromHostname(window.location.hostname) === 'prod'
    ? 'https://ws-api.toasttab.com/semi-payments'
    : 'https://ws-preprod-api.eng.toasttab.com/semi-payments'
export const PAYMENT_SESSIONS_CREATE = '/v1/public/sessions'

export enum AdyenResponse {
  AUTHORIZED = 'Authorised',
  CANCELLED = 'Cancelled',
  ERROR = 'Error',
  REFUSED = 'Refused'
}

export enum AdyenEnvironments {
  PRODUCTION = 'LIVE',
  TEST = 'TEST'
}

export const environment =
  envFromHostname(window.location.hostname) === 'prod'
    ? AdyenEnvironments.PRODUCTION
    : AdyenEnvironments.TEST

export const ADYEN_CHECKOUT_CONFIG = {
  environment,
  showPayButton: true,
  paymentMethodsConfiguration: {
    card: {
      hasHolderName: true,
      holderNameRequired: true,
      billingAddressRequired: false,
      brands: ['mc', 'visa', 'amex'],
      styles: {
        base: {
          color: '#252525',
          fontWeight: 400
        },
        placeholder: {
          color: '#252525'
        }
      }
    }
  }
}
