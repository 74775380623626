import React from 'react'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'
import { formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import { format, Formats, parseISO } from '@toasttab/buffet-pui-date-utilities'
import type { Currency } from '@toasttab/buffet-pui-country-utilities'
import type { Locale } from '@toasttab/buffet-pui-locale-utilities'
import { useData } from '../InvoiceView/DataProvider'
import { AppliedDiscount, SelectionsEntity } from '../../types/InvoiceTypes'
import InvoiceTotalRow from './InvoiceTotalRow'
import IntlTaxRow from './IntlTaxRow'
import ServiceChargeTotalRows from './ServiceChargeTotalRows'
import DiscountTotalRows from './DiscountTotalRows'
import { getDiscountLabel } from './discount-utils'
import { useInvoice } from '../InvoiceView/InvoiceProvider'
import {
  getItemQtyAfterRefund,
  getItemRefundQty,
  getPaymentRefund,
  isItemFullyRefunded
} from '../../utils/payments'
import { intlCountries } from '../../utils/utils'
import type { I18n } from '../../types/RestaurantTypes'
import _ from 'lodash'
import cx from 'classnames'

function renderModifiers(
  modifiers: SelectionsEntity[] | null | undefined,
  fullyRefunded: boolean
) {
  if (!modifiers || !modifiers.length) return null

  const uniqueModifierNames: { [key: string]: number } = {}
  modifiers.forEach((modifier) => {
    uniqueModifierNames[modifier.displayName] =
      (uniqueModifierNames[modifier.displayName] || 0) + 1
  })

  const displayMods = _.uniqBy(modifiers, (mod) => mod.displayName)

  return (
    <div className={cx('pl-4', fullyRefunded && 'line-through')}>
      {displayMods.map((modifier) => {
        const count = uniqueModifierNames[modifier.displayName]
        const name =
          count > 1 ? `${modifier.displayName} x${count}` : modifier.displayName
        return (
          <React.Fragment key={modifier.guid}>
            <p className={'text-secondary'}>{name}</p>
            {renderModifiers(modifier.modifiers, fullyRefunded)}
          </React.Fragment>
        )
      })}
    </div>
  )
}

function renderDiscounts(
  discounts: AppliedDiscount[] | null | undefined,
  renderType: 'name' | 'value',
  i18nContext: { currency: Currency; locale: Locale }
) {
  const { currency, locale } = i18nContext
  if (!discounts || !discounts.length) return null
  return discounts.map((discount) => (
    <p className={'text-secondary'} key={discount.guid}>
      {renderType === 'name'
        ? getDiscountLabel(discount)
        : formatCurrency(
            { amount: -discount.discountAmount!!, currency },
            locale
          )}
    </p>
  ))
}

function getDivider(paddingOrientation: string) {
  return (
    <tr>
      <td className={`p${paddingOrientation}-2`} colSpan={3}>
        <div className={'w-full border-b border-DEFAULT'} />
      </td>
    </tr>
  )
}

export default function OrderSummaryTable() {
  // IMPORTANT: this component is shared between invoices & events
  // if this is an event page, all the `useInvoice` variables will be undefined
  const { invoice, tipAmount, isDeposit, paymentAmount } = useInvoice()

  const {
    menuItemMap,
    totalAmount,
    check,
    restaurant,
    previousPayments
  } = useData()
  const { currency, locale } = useBuffetContext()

  const { country } = restaurant.i18n ? restaurant.i18n : ({} as I18n)
  const isIntlRx = intlCountries.includes(country)

  return (
    <table className={'w-full border-collapse border-0'}>
      <thead>
        <tr className={'w-full font-light'}>
          <th
            style={{ fontWeight: 400 }}
            className={
              'w-full text-left py-2 pr-2 type-overline text-secondary font-light'
            }
          >
            Item
          </th>
          {(!invoice || !invoice.hideUnitInfo) && (
            <th
              style={{ fontWeight: 400 }}
              className={
                'w-48 text-right p-2 type-overline text-secondary font-light'
              }
            >
              Qty
            </th>
          )}
          <th
            style={{ fontWeight: 400, minWidth: 80 }}
            className={
              'w-96 text-right py-2 pl-2 type-overline text-secondary font-light'
            }
          >
            Amount
          </th>
        </tr>
        {getDivider('b')}
      </thead>
      <tbody>
        {check?.selections
          ?.filter((lineItem) => !lineItem.voided)
          .map((lineItem, i) => (
            // h-1 here is a hack to ensure that height: 100% works on the inner divs
            // https://stackoverflow.com/questions/3215553/make-a-div-fill-an-entire-table-cell
            <tr key={i} className={'h-1'}>
              <td className={'align-top py-2 pr-2'}>
                <p className={'type-default space-x-1'}>
                  <span
                    className={cx(
                      'font-semibold',
                      isItemFullyRefunded(lineItem) && 'line-through'
                    )}
                  >
                    {lineItem.displayName || 'Unnamed Menu Item'}
                  </span>
                  {lineItem.refundDetails && (
                    <span className='inline-block italic'>
                      {isItemFullyRefunded(lineItem)
                        ? '(REFUNDED)'
                        : invoice.hideUnitInfo
                        ? '(PARTIAL REFUND)'
                        : `(REFUND QTY: ${getItemRefundQty(lineItem)})`}
                    </span>
                  )}
                </p>
                {menuItemMap &&
                  lineItem.item &&
                  menuItemMap[lineItem.item.guid] && (
                    <p className={'type-default'}>
                      {menuItemMap[lineItem.item.guid].description}
                    </p>
                  )}
                {renderModifiers(
                  lineItem.modifiers,
                  isItemFullyRefunded(lineItem)
                )}
                {renderDiscounts(lineItem.appliedDiscounts, 'name', {
                  currency,
                  locale
                })}
              </td>
              {(!invoice || !invoice.hideUnitInfo) && (
                <td className={'text-right p-2 align-top'}>
                  {lineItem.refundDetails ? (
                    <p className='italic'>
                      {!isItemFullyRefunded(lineItem) && (
                        <div>{getItemQtyAfterRefund(lineItem)}</div>
                      )}
                      <div className='line-through'>{lineItem.quantity}</div>
                    </p>
                  ) : (
                    lineItem.quantity
                  )}
                </td>
              )}
              <td
                style={{ height: 'inherit' }}
                className={'text-right py-2 pl-2 align-top'}
              >
                <div className={'flex flex-col justify-between h-full'}>
                  <p>
                    {formatCurrency(
                      { amount: lineItem.preDiscountPrice, currency },
                      locale
                    )}
                  </p>
                  {renderDiscounts(lineItem.appliedDiscounts, 'value', {
                    currency,
                    locale
                  })}
                </div>
              </td>
            </tr>
          ))}
        {getDivider('y')}
        <DiscountTotalRows />
        <ServiceChargeTotalRows taxable={false} gratuity={false} />
        <ServiceChargeTotalRows taxable gratuity={false} />
        <InvoiceTotalRow
          label={'Subtotal'}
          value={formatCurrency(
            { amount: check?.amount || 0, currency },
            locale
          )}
        />
        <ServiceChargeTotalRows taxable gratuity />
        {isIntlRx ? (
          <IntlTaxRow />
        ) : (
          <InvoiceTotalRow
            label={'Tax'}
            value={formatCurrency(
              { amount: check?.taxAmount || 0, currency },
              locale
            )}
          />
        )}
        <ServiceChargeTotalRows taxable={false} gratuity />
        {(invoice?.paymentDetails?.allowTipping || check.tipAmount > 0) && (
          <InvoiceTotalRow
            label={'Tip'}
            value={
              previousPayments.length > 0
                ? formatCurrency({ amount: check.tipAmount, currency }, locale)
                : formatCurrency({ amount: tipAmount, currency }, locale)
            }
          />
        )}
        {previousPayments.length > 0 && (
          <>
            <InvoiceTotalRow
              className='py-2 font-semibold type-large'
              label='Order total'
              value={formatCurrency(
                { amount: check.totalAmount, currency },
                locale
              )}
            />
            {getDivider('y')}
            <tr>
              <td colSpan={3} className={`text-left pr-2`}>
                Payments
              </td>
            </tr>
            {previousPayments.map((payment) => {
              return (
                <InvoiceTotalRow
                  key={payment.guid}
                  className='text-secondary'
                  label={
                    <div className='pl-4'>
                      {format(
                        parseISO(payment.paidDate),
                        Formats.date.long,
                        locale
                      )}
                      {payment.refund && (
                        <span className='inline-block italic'>{` (Refunded ${formatCurrency(
                          {
                            amount: getPaymentRefund(payment),
                            currency
                          },
                          locale
                        )})`}</span>
                      )}
                    </div>
                  }
                  value={formatCurrency(
                    { amount: -(payment.amount + payment.tipAmount), currency },
                    locale
                  )}
                />
              )
            })}
            <InvoiceTotalRow
              className='py-2 font-semibold type-large'
              label='Total paid'
              value={formatCurrency(
                {
                  amount: previousPayments.reduce(
                    (total, payment) =>
                      total + payment.amount + payment.tipAmount,
                    0
                  ),
                  currency
                },
                locale
              )}
            />
            {getDivider('y')}
            {invoice?.paymentDetails?.allowTipping && (
              <InvoiceTotalRow
                label={'Tip'}
                value={formatCurrency({ amount: tipAmount, currency }, locale)}
              />
            )}
          </>
        )}
        <InvoiceTotalRow
          className='py-2 font-semibold type-large'
          label={previousPayments.length > 0 ? 'Total due' : 'Total'}
          value={formatCurrency({ amount: totalAmount, currency }, locale)}
        />
        {isDeposit && (
          <InvoiceTotalRow
            className='py-2 font-semibold type-large'
            label={previousPayments.length ? 'Due now' : 'Deposit'}
            value={formatCurrency({ amount: paymentAmount, currency }, locale)}
          />
        )}
      </tbody>
    </table>
  )
}
