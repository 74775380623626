import React, { ReactNode } from 'react'
import { ErrorPage500 } from '@toasttab/buffet-pui-error-pages'
import { useNavigate } from 'react-router'
import { useConfigureSentryEvent } from '../Sentry/configureSentry'
import { MenuItemMap, RestaurantBranding } from '../../types/InvoiceTypes'
import { Event, EventStatus } from '../../types/EventTypes'
import { fetchEvent } from '../../api/invoices'
import { ErrorInterface, EventContextType } from '../../types/DataProviderTypes'
import DataProvider from '../InvoiceView/DataProvider'
import EventLoadingView from '../LoadingView/EventLoadingView'

// @ts-ignore doesn't like empty default context
export const EventContext = React.createContext<EventContextType>({})

export const useEvent = () => React.useContext(EventContext)

export default function EventProvider(props: {
  children: ReactNode
  token: string
}) {
  const { children, token } = props
  const navigate = useNavigate()
  const [event, setEvent] = React.useState<Event | null>(null)
  const [error, setError] = React.useState<ErrorInterface | null>(null)
  const [menuItemMap, setMenuItemMap] = React.useState<MenuItemMap | undefined>(
    undefined
  )
  const [restaurantBranding, setRestaurantBranding] = React.useState<
    RestaurantBranding | undefined
  >(undefined)
  const configureSentryEvent = useConfigureSentryEvent()

  React.useEffect(() => {
    fetchEvent(token)
      .then(({ status, event, menuItemMap, restaurantBranding }) => {
        if (status === 'EXPIRED') {
          navigate(`/invoice/estimateExpired?token=${token}`)
        }

        if (status === 'INVALID') {
          setError({ status: 500, message: 'Token is invalid' })
        }

        if (event && restaurantBranding) {
          configureSentryEvent({ guid: event.guid })
          setEvent(event)
          setMenuItemMap(menuItemMap)
          setRestaurantBranding(restaurantBranding)
        }
      })
      .catch((err) => {
        console.error('Failed to load event', err.message)
        setError(err)
      })
  }, [token, navigate])

  if (error) {
    return <ErrorPage500 />
  }

  const check = event?.order?.checks?.[0] || null

  if (!event || !restaurantBranding) {
    return <EventLoadingView />
  }

  const isConfirmed = event.status !== EventStatus.TENTATIVE
  const setIsConfirmed = () => {
    setEvent({
      ...event,
      status: EventStatus.CONFIRMED
    })
  }

  return (
    <EventContext.Provider
      value={{
        event,
        isConfirmed,
        setIsConfirmed
      }}
    >
      <DataProvider
        token={token}
        restaurantGuid={event.restaurantGuid}
        menuItemMap={menuItemMap}
        totalAmount={check?.totalAmount!!}
        check={check!!}
        restaurantBranding={restaurantBranding}
        loading={<EventLoadingView />}
      >
        {children}
      </DataProvider>
    </EventContext.Provider>
  )
}
