import React, { useState, useEffect } from 'react'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import SectionError from '../../ErrorBoundary/SectionError'
import { fetchInvoice } from '../../../api/invoices'
import { AdyenPaymentProps } from './types'
import { useData } from '../DataProvider'
import { AdyenPayment } from './AdyenPayment'

import './AdyenPayment.css'
import { useInvoice } from '../InvoiceProvider'

type AdyenPaymentContainerProps = Omit<AdyenPaymentProps, 'onAuth'>

const fetchInvoiceStatus = (token: string, lookup3pAccount: boolean) =>
  fetchInvoice(token, lookup3pAccount)
    .then(({ invoice }) => {
      return invoice
    })
    .catch((err) => {
      console.error('Failed to load invoice', err.message)
    })

export const AdyenPaymentContainer = (props: AdyenPaymentContainerProps) => {
  const [isCheckingInvoiceStatus, setIsCheckingInvoiceStatus] = useState(false)
  const [isInvoiceUpdateError, setIsInvoiceUpdateError] = useState(false)

  const { optedInTo3pAccountLookup } = useInvoice()

  const { orderGuid } = props

  const { token } = useData()

  useEffect(() => {
    if (isCheckingInvoiceStatus) {
      const MAX_ATTEMPTS = 20
      const ATTEMPT_DELAY = 500
      let attemptNum = 0
      const invoiceInterval = setInterval(() => {
        attemptNum++
        if (attemptNum >= MAX_ATTEMPTS) {
          // Set timeout message?
          clearInterval(invoiceInterval)
          setIsCheckingInvoiceStatus(false)
          console.error('Invoice did not update with payment status')
          setIsInvoiceUpdateError(true)
        }

        fetchInvoiceStatus(token, optedInTo3pAccountLookup).then((invoice) => {
          const invoiceOrder =
            invoice?.invoiceOrders?.find((i) => i.orderGuid === orderGuid) ||
            null
          const paymentOrder = invoiceOrder?.order
          const paymentCheck = paymentOrder?.checks[0]

          const isPaid =
            invoice?.status === 'PAID' ||
            invoice?.status === 'CLOSED' ||
            paymentCheck?.paymentStatus === 'PAID' ||
            paymentCheck?.paymentStatus === 'CLOSED'

          if (isPaid) {
            // Refresh page to show thank-you message
            window.location.reload()
          }
        })
      }, ATTEMPT_DELAY) // Check invoice for up to 10 seconds
    }
  }, [isCheckingInvoiceStatus, token, orderGuid, optedInTo3pAccountLookup])

  const onAuth = () => {
    setIsCheckingInvoiceStatus(true)
  }

  return (
    <div
      className={`adyen-checkout-wrapper ${
        isCheckingInvoiceStatus ? 'processing' : ''
      }`}
    >
      {isInvoiceUpdateError ? (
        <SectionError />
      ) : (
        <AdyenPayment {...props} onAuth={onAuth} />
      )}
      <div className='adyen-checkout-overlay'>
        <MerryGoRound className='adyen-checkout-spinner' size='lg' />
      </div>
    </div>
  )
}
