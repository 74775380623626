import { apiGet } from '../../api/util'
import { useEffect, useState } from 'react'

export interface AdsConversionTag {
  conversionTagId: string
  eventLabel: string
  eventType: ConversionTagEventType
  conversionName: string
}

export type ConversionTagEventType = 'SUBMIT_LEAD_FORM' | 'PURCHASE'

const getAdsConversionTag = async (
  managementSetGuid: string
): Promise<AdsConversionTag[]> => {
  let r = await apiGet(`brands/v1/google-ads/public/conversion-tags/catering`, {
    'Toast-Management-Set-GUID': managementSetGuid
  })
  return await r.json()
}

export const useAdsConversionTags = (managementSetGuid: string | undefined) => {
  const [tags, setTags] = useState<AdsConversionTag[] | undefined>()

  useEffect(() => {
    if (managementSetGuid) {
      getAdsConversionTag(managementSetGuid)
        .then((tags) => {
          setTags(tags)
        })
        .catch(() => {
          setTags(undefined)
        })
    }
  }, [managementSetGuid])

  const leadsTags = tags?.filter((tag) => tag.eventType === 'SUBMIT_LEAD_FORM')

  return {
    data: leadsTags && leadsTags.length > 0 ? leadsTags[0] : undefined
  }
}
