import React from 'react'

import { Marker as GoogleMapsMarker } from '@react-google-maps/api'

type Props = {
  position: google.maps.LatLng | google.maps.LatLngLiteral
  onClick: () => void
  isSelected?: boolean
  title: string
}
const filledPin =
  'M32.717 27.6127C29.6735 32.9344 17.4996 50 17.4996 50C17.4996 50 5.32568 32.9344 2.2822 27.6127C-0.761275 22.291 -0.761496 14.7601 2.2822 9.58914C6.85045 1.8281 12.4992 0 17.4996 0C22.5 0 27.9561 1.5 32.717 9.58914C35.7605 14.7602 35.7605 22.291 32.717 27.6127Z'

const MapMarker = (props: Props) => {
  return (
    <GoogleMapsMarker
      position={props.position}
      onClick={props.onClick}
      icon={{
        path: filledPin,
        fillColor: '#FF0000',
        fillOpacity: props.isSelected ? 1 : 0.5,
        strokeColor: '#FFF',
        strokeWeight: 0,
        scale: 1,
        // @ts-ignore
        anchor: { x: 17.5, y: 50 }
      }}
      title={props.title}
    />
  )
}

export default MapMarker
