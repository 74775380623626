import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useAdsConversionTags } from './ads'

interface SubmissionTagProps {
  managementSetGuid: string
}

const SubmissionTag: React.FC<SubmissionTagProps> = ({ managementSetGuid }) => {
  const { data } = useAdsConversionTags(managementSetGuid)

  if (data) {
    return (
      <Helmet>
        <script>
          {`gtag('event', 'conversion', {
                'send_to': '${data.conversionTagId}/${data.eventLabel}',
                'value': 1.0,
                'currency': 'USD'
              });  
        `}
        </script>
      </Helmet>
    )
  }

  return null
}

export default SubmissionTag
